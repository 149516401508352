var stickyHeader = true;
var flyoutNav = true;
// var fullscreenNav = false;
var hoverSensitiveNav = true;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

var feedTitleLinks = ['/Blogs/blog/Category/stories', '/pages/category/uk-jobs', '/pages/category/mdt'];
// var feedItemsCarouselBreakpoint = 9999999999; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = false;
// var feedItemsCarouselAutoplaySpeed = 4000;

var subsiteHijackMainNav = ['subsite-test'];
var subsiteTitleNotClickable = true;


// Home feeds - Slick it!
$('.homeFeed:not(.homeFeedsnippets):not(.subsiteFeedBox1)').each(function() {
  $(this).find('.feedList').slick({
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 3,
    // centerMode: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 10000,
    infinite: true,
    responsive: [
      {
        breakpoint: 906,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 601,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
});


// Home feeds - Slick those testimonials!
$('.homeFeedsnippets:not([class*="video"]):not(.subsiteFeed)').find(".feedList").slick({
  slidesToShow: 1,
  centerMode: false,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  pauseOnHover: true,
  pauseOnDotsHover: true,
  autoplay: true,
  autoplaySpeed: 6000
});


// Run function on resize
$(document).ready(function () {
  reduceSummaryText();
  carouselReadMoreSize();
  homeFeedBackgroundColour();
  $(window).resize(function() {
    reduceSummaryText();
    carouselReadMoreSize();
    homeFeedBackgroundColour();
  });
});


//  Calculate height of headerText
function homeFeedBackgroundColour() {
  var $homeFeedBox2Background = $('<span class="feedBackgroundBox2" />');
  var homeFeedBox1Height = $('.homeFeedBox1').outerHeight(true);
  var homeFeedBox2Height = $('.homeFeedBox2').outerHeight(true);
	
  $homeFeedBox2Background.prependTo('.homeFeedsWrapper:not(.subsiteFeedsWrapper)')
  $homeFeedBox2Background.css({
    'top' : homeFeedBox1Height,
    'height' : homeFeedBox2Height
  });


  var feedBackgroundBoxTestimonials = $('<span class="feedBackgroundBoxTestimonials" />');
  var feedTestimonialsHeight = $('.homeFeedsnippets:not([class*="video"]):not(.subsiteFeed)').outerHeight(true);
  
  $(feedBackgroundBoxTestimonials).appendTo('.homeFeedsWrapper:not(.subsiteFeedsWrapper)')
  $(feedBackgroundBoxTestimonials).css({
   'height' : feedTestimonialsHeight,
  });

}


//  Reduce  summary text on mobile
function reduceSummaryText() {
    $('.homeFeature').each(function () {
      var $cardText = $(this).find('.homeFeatureDetailsWrapper > p')
      $cardText.text($cardText.text().substring(0, 150));
    });

    $('.homeFeed:not(.homeFeedsnippets) .feedItem').each(function () {
      $(this).find('span.readMore').remove();
      var $cardText = $(this).find('.feedItemDetailsWrapper > p')
      $cardText.text($cardText.text().substring(0, 150));
    });

    $('.listedPost').each(function () {
      $(this).find('.readMore').remove();
      var $cardText = $(this).find('.postSummary')
      $cardText.text($cardText.text().substring(0, 150));
    });
};


// Carousel details - Layout
function carouselReadMoreSize() {
  if ($(window).width() > 768) {	
    $('.carouselSlide').each(function() {
      var carouselReadMoreWidth = $('.carouselSlideReadMore').outerWidth();
      var carouselHeadingAndSummary = $('.carouselSlideHeading, .carouselSlideSummary');

      carouselHeadingAndSummary.css({ 'width': 'calc(100% - ' + carouselReadMoreWidth + 'px - 1rem)' });
    });
  }
}


// Homepage feed - Video
$('.homeFeedsnippets:not(.subsiteFeed)').each(function() {
  var $this = $(this);
  var $iframe = $this.find('iframe');
  var feedItem = $this.find('.feedItem');
  $iframe.prependTo(feedItem).wrap('<div class="home-feed-image-link" />');
});


// Cursor - Setup
$('#cursor').css('cursor', 'url("../assets/logo.svg"), auto');


// Global Alert - Close button
$('<span class="closeButton" />').appendTo('.GlobalAlert');
$('.GlobalAlert .closeButton').on('click', function () {
  $('.GlobalAlert').addClass('hidden');
});


// Nav buttons - Move to top
$(document).ready(function () {
  $('.menuMain .mainCallToAction').insertAfter('.menuMain .header-search');
});

function setCarouselControlsHeight() {
  $('ul.carouselControls').css('height', $('.carouselSlideTitle').first().css('height'));
}

setCarouselControlsHeight()

$(window).resize(function(){
  setCarouselControlsHeight();
})